import { FormattedMessage } from "react-intl";
import { useInView } from "react-intersection-observer";

export const Steps = ({
	stepTitleKey,
	stepDescOneKey,
	stepDescTwoKey,
	number,
	multipleImageComponent,
	imageComponent,
	titleStyles = "font-bold",
}) => {


	const { ref, inView } = useInView();

	return (
		<div
			ref={ref}
			className={`ml-5 md:ml-0 
		${number % 2 === 1 ? "animate__fadeInLeft" : "animate__fadeInRight"}
		${number % 2 === 1 ? "md:mb-7" : "md:mt-7"}
		${inView ? `animate__animated  animate__delay-${Math.min(5, number - 1)}s` : ""}`}>

			<div className={`md:mb-16 mb-12 shadow-xl rounded-xl md:h-[16rem] h-[20rem] `}>
				<div className="w-[0%] absolute " style={{ left: "-100vw", top: "-2vw" }}>
					<div className="flex flex-col items-center md:items-start">
						<div className="rounded-full bg-gradient-to-br from-[#A1BF03] via-[#1E8734] to-[#1D8634] py-3 px-5 ">
							<p className="font-black text-white md:text-3xl text-xl">{number}</p>
						</div>
					</div>
				</div>
				<h3 className={`text-3xl md:mb-8 ${titleStyles} text-text-title text-center pt-8 px-2`}>
					<FormattedMessage id={stepTitleKey} />
				</h3>
				<div className="flex flex-column align-middle h-100 items-center">

					<div className="w-full text-text-content mt-2 px-5">
						<p className="mb-8 text-center r-text">
							<FormattedMessage id={stepDescOneKey} className="r-text" />
						</p>
						<p className="text-center  r-text">
							<FormattedMessage id={stepDescTwoKey} className="r-text" />
						</p>

					</div>
					{/* <div className="flex w-full flex-col items-center md:flex-row place-content-center w-[25%] pr-5">

						{multipleImageComponent ? multipleImageComponent : imageComponent}
					</div> */}
				</div>
			</div>

		</div>

	);
};
