import { FormattedMessage } from "react-intl";
import { useInView } from "react-intersection-observer";

import { DownloadOptions } from "components/DownloadOptions/DownloadOptions";
import YouTube from 'react-youtube';

export const HeroBanner = () => {
	const onPlayerReady = (event) => {
		console.log(event.target)
	}

	const opts = {
		width: "100%",
		innerHeight: "100%",
		outerHeight: "100%",
	    playerVars: { 'autoplay': 1, 'controls': 1, mute: 1 },

	};
	return (
		<div className="banner py-8 md:py-8 bg-gradient-to-br from-[#1D8634] via-[#1E8734] to-[#A1BF03] flex flex-wrap relative">
			<div className="pt-6 w-full md:w-1/2 flex flex-col items-center md:justify-center px-6 md:p-4 md:py-4 md:px-8 xl:px-16 3xl:px-32 text-white mb-10 md:mb-0">
				<div className={`md:mx-2 w-[100%] z-20 h-100 `}>
					<YouTube videoId="xVsCfsfB31Q" opts={opts} onReady={onPlayerReady}  />
				</div>
			</div>
			<div className="pt-6 w-full md:w-1/2 flex flex-col items-center md:justify-center px-6 md:p-4 md:py-4 md:px-8 xl:px-16 3xl:px-32 text-white mb-10 md:mb-0">
				<div className="relative z-2">
					<h1 className="text-center md:text-left text-3xl md:text-6xl xl:text-7xl font-bold md:leading-14 xl:leading-20 mb-4">
						<FormattedMessage id="APP.HERO.BANNER.TITLE" />
					</h1>
					<p className="text-center md:text-left text-lg md:text-xl lg:text-2xl mb-8 lg:mb-10">
						<FormattedMessage id="APP.HERO.BANNER.DESCRIPTION" />
					</p>
					<DownloadOptions />
				</div>
				<img
					src={`${process.env.PUBLIC_URL}/assets/wave.svg`}
					alt="wave illustration"
					className="absolute w-[500px] md:w-[1000px] wave-width bottom-0 -right-[10rem] md:-right-[32rem] lg:-right-[19rem] 3xl:w-[1500px] 3xl:-right-[30rem]"
				/>
			</div>

		</div>
	);
};
